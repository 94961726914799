<template>
    <div class="section">
        <client-period-filters></client-period-filters>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card class="mb-3" title-tag="h5">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5>{{ $t('client.pages.costs.doughnutChart.card.labels.title') }}</h5>
                        </div>
                        <div v-if="!refreshData">
                            <h5>{{ $t('client.pages.costs.doughnutChart.card.labels.total') }} {{ countsBySkill.total }}</h5>
                        </div>
                    </div>
                    <spinner v-if="refreshData"></spinner>
                    <doughnut-chart
                        :data="countsBySkill.items.map(a => a.percentage)"
                        :labels="countsBySkill.items.map(a => a.skill_name)"
                        :page="barChartPages.DASHBOARD.INTERVENTIONS"
                        :background-colors="countsBySkillColors"
                        @generateLegendEvent="setLegend"
                        v-if="!refreshData && countsBySkill.items.length > 0"
                    ></doughnut-chart>
                    <!--<div class="legend" v-html="legend"></div>-->
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card class="mb-3" title-tag="h5">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5>{{ $t('client.pages.interventions.doughnutChart.card.labels.title') }}</h5>
                        </div>
                        <div v-if="!refreshData">
                            <h5>{{ $t('client.pages.interventions.doughnutChart.card.labels.total') }} {{ $n(costsBySkill.total, 'currency') }}</h5>
                        </div>
                    </div>
                    <spinner v-if="refreshData"></spinner>
                    <doughnut-chart
                        :data="costsBySkill.items.map(a => a.percentage)"
                        :labels="costsBySkill.items.map(a => a.skill_name)"
                        :page="barChartPages.DASHBOARD.COSTS"
                        :background-colors="costsBySkillColors"
                        v-if="!refreshData && costsBySkill.items.length > 0"
                    ></doughnut-chart>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ClientPeriodFilters from '@/components/client/dashboard/ClientPeriodFilters';
import DoughnutChart from '@/components/shared/chart/DoughnutChart';
import InputFilterEvents from '@/views/client/InputFilterEvents';
import { EVENTS } from '@/constants/client/events';
import { DashboardRepository } from '@/repositories';
import { CHART } from '@/constants/client/chart';
import Spinner from '@/components/shared/element/Spinner';
import { DASHBOARD_CLIENT } from '@/constants';

export default {
    name: 'ClientDashboardDistributionByPercentage',
    extends: InputFilterEvents,
    components: {
        ClientPeriodFilters,
        DoughnutChart,
        Spinner
    },
    data: () => ({
        costsBySkill: null,
        countsBySkill: null,
        refreshData: true,
        barChartPages: CHART,
        costsBySkillColors: [],
        countsBySkillColors: [],
        legend: ''
    }),
    created() {
        this.getData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_AND_PERIOD_FILTER_CHANGE, () => {
            this.costsBySkillColors = [];
            this.countsBySkillColors = [];
            this.getData();
        });
    },
    methods: {
        getData() {
            this.refreshData = true;
            DashboardRepository.getCostsBySkill(this.getFilters())
                .then(response => {
                    this.costsBySkill = response.data.data;
                    this.costsBySkill.items.sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage));
                    this.costsBySkill.items.map(item => {
                        if (item.skill_id in DASHBOARD_CLIENT.DOUGHNUT_COLORS_BY_SKILL_ID) {
                            this.costsBySkillColors.push(DASHBOARD_CLIENT.DOUGHNUT_COLORS_BY_SKILL_ID[item.skill_id]);
                        }
                    });
                    DashboardRepository.getCountsBySkill(this.getFilters())
                        .then(response => {
                            this.countsBySkill = response.data.data;
                            this.countsBySkill.items.sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage));
                            this.countsBySkill.items.map(item => {
                                if (item.skill_id in DASHBOARD_CLIENT.DOUGHNUT_COLORS_BY_SKILL_ID) {
                                    this.countsBySkillColors.push(DASHBOARD_CLIENT.DOUGHNUT_COLORS_BY_SKILL_ID[item.skill_id]);
                                }
                            });
                            this.refreshData = false;
                        })
                        .catch(error => {
                            this.refreshData = false;
                            console.log(error);
                        });
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        },
        setLegend(html) {
            this.legend = html;
        }
    }
};
</script>

<style>
.legend ul {
    list-style-type: none;
    margin-top: 10px;
}
.legend li {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 10px;
}
.legend li .color-block {
    width: 25px;
    height: 20px;
    margin-right: 5px;
}
</style>
