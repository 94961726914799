import { Doughnut } from 'vue-chartjs';
import { CHART } from '@/constants/client/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import ChartOutlabels from 'chartjs-plugin-piechart-outlabels';
// import ChartLabels from 'chartjs-plugin-labels';

export default {
    name: 'DoughnutChart',
    extends: Doughnut,
    props: {
        data: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        },
        page: {
            type: String,
            required: true
        },
        backgroundColors: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            datacollection: {
                labels: this.labels,
                datasets: [
                    {
                        backgroundColor: this.backgroundColors,
                        data: this.data
                    }
                ]
            },
            options: {
                plugins: {
                    datalabels: {
                        color: 'white',
                        textAlign: 'center',
                        font: {
                            weight: 'bold',
                            size: 13
                        },
                        display: 'auto',
                        formatter: function(value) {
                            return value + '%';
                        }
                    }
                    // outlabels: {
                    //     text: '%l %p',
                    //     color: 'white',
                    //     stretch: 45,
                    //     font: {
                    //         resizable: true,
                    //         minSize: 8,
                    //         maxSize: 14
                    //     },
                    //     valuePrecision: 1,
                    //     percentPrecision: 2
                    // },
                    // labels: {
                    //     render: 'label',
                    //     fontColor: '#000',
                    //     position: 'outside',
                    //     textMargin: 4,
                    //     outsidePadding: 2
                    //     // display: 'auto'
                    // }
                },
                legend: {
                    display: true,
                    position: 'right'
                },
                legendCallback: function(chart) {
                    var text = [];
                    text.push('<ul>');
                    for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
                        text.push('<li><span class="color-block" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '">&nbsp');
                        text.push('</span>');
                        text.push('<span class="info">');
                        if (chart.data.labels[i]) {
                            text.push(chart.data.labels[i]);
                            text.push(' - ');
                            text.push(chart.data.datasets[0].data[i]);
                        }
                        text.push('</span></li>');
                    }
                    text.push('</ul>');
                    return text.join('');
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            if (this.page === CHART.DASHBOARD.COSTS || this.page === CHART.DASHBOARD.INTERVENTIONS) {
                                let label = data['labels'][tooltipItem['index']] || '';
                                if (label) {
                                    label += ': ';
                                }
                                label += data['datasets'][0]['data'][tooltipItem['index']];
                                label += '%';
                                return label;
                            }
                        }
                    }
                }
            }
        };
    },
    mounted() {
        this.addPlugin(ChartDataLabels);
        // this.addPlugin(ChartOutlabels);
        // this.addPlugin(ChartLabels);
        this.renderChart(this.datacollection, this.options);
        // GENERATE custom legend
        // const HTML = this.$data._chart.generateLegend();
        // this.$emit('generateLegendEvent', HTML);
    },
    methods: {
        getColors() {
            let pallet = [
                '#0074D9',
                '#FF4136',
                '#2ECC40',
                '#FF851B',
                '#7FDBFF',
                '#B10DC9',
                '#FFDC00',
                '#001f3f',
                '#39CCCC',
                '#01FF70',
                '#85144b',
                '#F012BE',
                '#3D9970',
                '#111111',
                '#AAAAAA'
            ];
            let colors = [];

            for (let i = 0; i < this.data.length; i++) {
                colors.push(pallet[i % pallet.length]);
            }

            return colors;
        }
    }
};
